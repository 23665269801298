<template>
<div class="shadow-sm">
    <b-navbar id="nev-bar" toggleable="lg" type="light" fixed="top" class="nev-bar-bg shadow-sm" >
        <b-container>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-navbar-brand
                @click="goToRide('/')"
                id="header-logo">
                <img 
                    :src="require('@/assets/images/Logo.svg')"
                    height="37px"
                    width="156px"
                    class="clikable"
                    alt="tripplanner">
            </b-navbar-brand>

            <b-collapse id="nav-collapse" class='main-menu' is-nav>
                <b-navbar-nav >
                    <b-nav-item style="margin-left:1.5rem; margin-right:1.5rem;" class='main-menu' @click="goToRide('/')">דף הבית</b-nav-item>
                    <b-nav-item style="margin-left:1.5rem; margin-right:1.5rem;" @click="goToRide('/about')">אודות</b-nav-item>
                    <b-nav-item style="margin-left:1.5rem; margin-right:1.5rem;" @click="goToRide('/search_ride')">צפיה בטיולים</b-nav-item>
                    <b-nav-item style="margin-left:1.5rem; margin-right:1.5rem;" v-if="isAppLoggedIn" @click="goToRide('/user_rides')">הטיולים שלי</b-nav-item>
                </b-navbar-nav>
            </b-collapse>

            <a
             href='https://www.facebook.com/Tripplaner-100134939297544'
             aria-label="goo to TripPlanner facebook page"
             target='_blank'>
                <img
                :src="require('@/assets/images/facebook_nav_bar_icon.png')"
                height="24px"
                width="26px"
                alt="facebookIcon"
                class="nev-bar_facebook_icon">
            </a>

            <div v-if="isAppLoggedIn" class="horizontal">
               <a class="trip-header-btn" @click="appLogOut">התנתקות</a>
            </div>
            <div v-else >
                <a class="trip-header-btn" @click="openLoginForm">התחברות</a>
            </div>
        </b-container>
    </b-navbar>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AppHeader',
  computed: mapGetters(['isAppLoggedIn',
                        'userName',
                        'email']),
  methods: { ...mapActions(['login', 'appLogOut','validateAndupdateAppToken', 'openLoginForm']),
      goToRide( link ) {
      this.$router.push(link)
    },
  },
  created() {
    this.validateAndupdateAppToken();
  }

};
</script>

<style scoped>
.form-popup {
  display: none;
}

.shadow-sm {
  box-shadow: 0 .8rem .5rem rgba(0, 0, 0, .075) !important;
}

</style>
