import config from '../../common/config';


const state = {
  center: { lat: 45.508, lng: -73.587 },
  markers: [],
  placesToSet: [],
  currentPlace: null,
  appPointsToUpdateIfMapLowdAfterPoints: [],
  appMap: null,
  appMaps: [],
  appMapsMarkers: [],

};
const getters = {
  center: state => state.center,
  markers: state => state.markers,
  placesToSet: state => state.placesToSet,
  currentPlace: state => state.currentPlace,
  appPointsToUpdateIfMapLowdAfterPoints: state => state.appPointsToUpdateIfMapLowdAfterPoints,
  appMap: state => state.appMap,
  appMaps: state => state.appMaps,
  appMapsMarkers: state => state.appMapsMarkers,
  appMapFromMaps: state => ( index ) => state.appMaps[ index ],
  

};
const actions = {
  addMap({ getters, commit, dispatch }, kwargs ){
    // ***** this part of code is creating new map (only after getting the google scripts fro gmap-map)
    // ***** and lowding it to part at the code with the id map_canvas
    // it is nesesery if we whant to lowd more than one map!!!!!!!!!!!!
    // const elementId = "map_canvas"
    // const appMaps = getters.appMaps;
    // state.appMaps.length
    // console.log(google.maps.MapTypeId.ROADMAP )
    // var myOptions = {
    //   zoom: 8,
    //   center: {lat:0, lng:0},
    //   mapTypeId: google.maps.MapTypeId.ROADMAP
    // }
    // var map = new google.maps.Map(document.getElementById( kwargs.elementId ), myOptions);

    const map = getters.appMap;
    var i;
    var markers = [];
    var pointsToUpdateIfTheMapLodedAfterThePoints = [];
    for( i=0; i < kwargs.points.length; i++ ){
      const reversedOrdering = kwargs.points.length - 1 - i;
      const point = { latPoint: kwargs.points[reversedOrdering].lat,
                      lngPoint: kwargs.points[reversedOrdering].lng,
                      iconUrl: kwargs.points[reversedOrdering].iconUrl,
                    };
      dispatch( 'addMarkerByLatAndLng', {
                  map:map,
                  rideMarkers: markers,
                  point:point
                });
      pointsToUpdateIfTheMapLodedAfterThePoints.push(point)
    }
    commit( 'setAppPointsToUpdateIfMapLowdAfterPoints', pointsToUpdateIfTheMapLodedAfterThePoints );
    commit( 'setAddAppMaps', { map: map, markers: markers } );
    dispatch( 'fitBoundesToMarkers', {
      appMap:map,
      markers: markers,
    });

  },
  thisMapWereCreated:({getters, dispatch}) =>{
    const appMap = getters.appMap;
    const points = getters.appPointsToUpdateIfMapLowdAfterPoints;
    var i;
    var markers = [];
    for( i=0; i < points.length; i++ ){
      dispatch( 'addMarkerByLatAndLng', {
        map:appMap,
        rideMarkers: markers,
        point:points[i]
      });
    }
    dispatch( 'fitBoundesToMarkers', {
      appMap:appMap,
      markers: markers,
    });
  },
  addMarkerByLatAndLngToGlobalMap: ({dispatch, getters }, point) => {
    const appMap = getters.appMap;
    // const appMap = getters.appMapFromMaps(0).map;
    const rideMarkers = getters.markers;
    dispatch( 'addMarkerByLatAndLng', {
      map:appMap,
      rideMarkers: rideMarkers,
      point:point
     });
    },

  removeAllMarkersToGlobalMAp: ({dispatch, getters}) =>{
    const markers = getters.markers;
    dispatch( 'removeAllMarkers', markers );
  },

  fitBoundesToMarkersAtGlobalMap: ({dispatch, getters }) => {
    const appMap = getters.appMap;
    const markers = getters.markers;
    dispatch( 'fitBoundesToMarkers', {
      appMap:appMap,
      markers: markers
     });

  },
  // addMarker: ({state, commit }) => {
  //   if (state.currentPlace) {
  //     const marker = {
  //       lat: state.currentPlace.geometry.location.lat(),
  //       lng: state.currentPlace.geometry.location.lng()
  //     };
  //     commit( 'addMarker', marker );
  //     commit( 'addPlace', state.currentPlace );
  //     commit( 'setCenter', marker);
  //     commit( 'setCurrentPlace', null);
  //   }
  // },
  async geolocate({commit}) {
    const position = await navigator.geolocation.getCurrentPosition()
    const center = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    };
    commit('center',center);
  },
  setOneMarker({ dispatch }, point ) {
    dispatch('removeAllMarkersToGlobalMAp');
    dispatch( 'addMarkerByLatAndLngToGlobalMap', point );
    dispatch( 'fitBoundesToMarkersAtGlobalMap');
  },
  async updatemarkerByColersAndLetter( __,points ){
    var i;
    for( i = 0; i < points.length; i++ ){
      if( i < config.config.BLUE_MAP_POINT_WITH_NUMERATOR.length )
        points[i].iconUrl = config.config.BLUE_MAP_POINT_WITH_NUMERATOR[i].IMAGE_URL;
      else
        points[i].iconUrl = config.config.BLUE_MAP_POINT_WITH_NUMERATOR[ config.config.BLUE_MAP_POINT_WITH_NUMERATOR.length -1 ].IMAGE_URL;
    }
    return points;
  },
  async updatemarkerByColers( __,points ){
    var i;
    for( i = 0; i < points.length; i++ ){
      if( i < config.config.BLUE_MAP_POINT_WITH_NUMBERS.length )
        points[i].iconUrl = config.config.BLUE_MAP_POINT_WITH_NUMBERS[i].IMAGE_URL;
      else
        points[i].iconUrl = config.config.BLUE_MAP_POINT_WITH_NUMBERS[ config.config.BLUE_MAP_POINT_WITH_NUMBERS.length - 1 ].IMAGE_URL;
    }
    return points;
  },
}
const mutations = {
  setCurrentPlace( state, place ) {
    state.currentPlace = place;
  },
  setAppMap( state, appMap ) {
    state.appMap = appMap;
    this.dispatch( 'thisMapWereCreated' );
  },
  setAddAppMaps( state, appMap ) {
    state.appMaps.push( { appMap:appMap.map, markers:appMap.markers} );
  },
  setClearAppMaps( state ) {
    while ( state.appMaps.length > 0) {
      state.appMaps.pop();
    }
    // we should consider to remove this one
    while ( state.appMapsMarkers.length > 0) {
      state.appMapsMarkers.pop();
    }
  },
  // we should consider to remove this one
  setAddAppMapsMarkers( state, marker ) {
    state.appMapsMarkers.push( marker );
  },
  addMarker( state, marker ) {
    state.markers.push( marker );
  },
  addPlace( state, place ) {
    state.placesToSet.push( { place } );
  },
  setCenter( state, center ) {
    state.center =  center;
  },
  setAppPointsToUpdateIfMapLowdAfterPoints( state, points ) {
    state.appPointsToUpdateIfMapLowdAfterPoints = points;
  },

};

export default {
  state,
  getters,
  actions,
  mutations
};
