<template>
  <div>
    <b-modal
      id="dynamicModal"
      modal-class="modal-cover"
      v-model="isVisible"
      hide-backdrop
      hide-footer
      content-class="shadow-lg"
    >
      <section class="text-center">
        <b-row v-if="dynamicModal">
          <b-col cols="12" class="py-2">
            <h3 class="font-weight-medium card-title">
              {{dynamicModal.text}} 
            </h3>
          </b-col>

          <b-col cols="12" class="sub_text_popup py-2">
            <p>
              {{dynamicModal.subText}}
            </p>
          </b-col>

          <b-col cols="12" class="py-2">
            <b-btn
              pill
              variant="warning"
              class="px-5 py-2 text-white"
              @click="handle_function_call(dynamicModal.action)"
              >{{dynamicModal.actionText}}</b-btn
            >
          </b-col>
        </b-row>
      </section>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import config from '@/common/config';

export default {
  props: ["value"],
  computed: {
    ...mapGetters([ 'allRide',
                    'dynamicModal'
                  ]),
    isVisible: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: { 
    ...mapActions([ 'addRideToUser',
                    'openLoginForm',
                    'showModal',
     ]),
    closePopup(){
      this.showModal('')
    },
    goToAddRideWithAlboumDetailesForm(){
      window.open(config.config.GOOGLE_FORM_ADD_NEW_RIDE_WITH_ALBUM, '_blank')
      this.showModal('')
    },
    openLoginFormPresed(){
        this.showModal('')
        // this.$store.commit("setRideInformationId", rideId);
        // this.value = false;
        this.openLoginForm()
      },
    handle_function_call(function_name) {
      this[function_name]()
    },
  },
};
</script>



<style scoped>
.modal.modal-cover .modal-content,
.modal.modal-cover .modal-footer,
.modal.modal-cover .modal-header {
  border: none;
}
.height-50 {
  height: 50px !important;
}
.width-50 {
  width: 50px !important;
}
.sub_text_popup{
  font-size:1.5rem;
}
</style>