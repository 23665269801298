<template>
  <div>
   <SingleRideHeader
      presentedText="פרטי הטיול"
    />
      <div class="trip-contact-form-section after_page_header">
        <div class="container">
          <div class="trip-contact-wrap">
            <h4>פרטי האטרקציה</h4>
            <div class="row">
              <div class="col-lg-5 offset-lg-3">
                <div class="trip-contact-column">
                  <div class="trip-contact-field">
                    <!-- <label>תאריך הטיול<span>*</span></label> -->
                      <div class="trip-input-field">
                          <flat-pickr
                            v-model="date"
                            class="border-0 bg-white form-control datepicker text-right"
                            :placeholder=datePickerPlaceholder
                            :config=datePickerConfig
                          ></flat-pickr>
                          <img
                          class="coulunder_image"
                          :src="require('@/assets/images/icons/icons8-edit-calendar-50.png')"
                          >
                        </div>
                    </div>
                  <div class="trip-contact-field">
                    <!-- <label>כותרת<span>*</span></label> -->
                    <div class="trip-contact-input">
                      <input
                        v-model="localRideInformationTitle"
                        placeholder="כותרת הטיול"
                        type="text"
                        required
                      >
                    </div>
                  </div>
                  <div class="trip-contact-field">

                    <div class="trip-contact-input">
                      <input
                        v-model="localRideInformationSherdPhotosAlbomLink"
                        placeholder="לינק לאלבום תמונות ששותף"
                        type="text"
                        
                      >
                    </div>

                  </div>
                  <div class="trip-contact-field">
                    <div class="trip-contact-input">

          <b-dropdown
            variant="dark-oultine"
            size="lg"
            right
            block
            no-caret
          >
            <template #button-content>
              <div class="d-flex align-items-center">
                <span class="expand-arrow"></span>
                <h3
                  >הרכב גילאי המשתתפים</h3
                >
              </div>
            </template>
            <b-dropdown-form
              style="width: 280px; text-align: right"
            >
              <b-row class="align-items-center mb-2">
                <b-col>
                  <b-form-spinbutton
                    v-model="numbrOfAdoult"
                    min="0"
                    size="lg"
                  ></b-form-spinbutton>
                </b-col>
                <b-col>
                  <h4>
                  בוגרים
                  </h4>
                  </b-col>
              </b-row>
              <b-row class="align-items-center mb-2">
                <b-col>
                  <b-form-spinbutton
                    v-model="numbrOfTeen"
                    min="0"
                    size="lg"
                  ></b-form-spinbutton>
                </b-col>
                <b-col>
                  <h4>
                    נערים 
                  </h4>
                </b-col>
              </b-row>
              <b-row class="align-items-center">
                <b-col>
                  <b-form-spinbutton
                    v-model="numbrOfChild"
                    min="0"
                    size="lg"
                  ></b-form-spinbutton>
                </b-col>
                <b-col>
                  <h4>
                    ילדים
                  </h4>
                  </b-col>
              </b-row>
            </b-dropdown-form>
          </b-dropdown>
        </div> 
        </div> 
        </div> 
        <div class="trip-contact-field">
          <div class="trip-contact-input">
            <textarea
              placeholder="פרטים כללים על הטיול"
              v-model="localRideInformationText"
              >
            </textarea>
          </div>
        </div>

        <div class="trip-contact-field">
          <!-- <label>פרטים כללים על הטיול</label> -->
          <div class="trip-contact-input">
            <input
              id="physical-disabilities"
              v-model="physicalDisabilities"
              type="checkbox"
            >
            <span class="save_ride_checkboxtext">
              מתאים גם לאנשים עם מוגבלויות
            </span>
                    </div>
                  </div>


                    <div class="trip-contact-field">
                  <!-- <p v-if="newAttractionErrores.length">
                    <label>בבקשה תתקנו את השדות הבאים : </label>
                    <ul class="input-errors" >
                      <li v-for="( error, index ) in newAttractionErrores" :key="index"> {{error}}</li>
                    </ul>
                  </p> -->
                    </div>

                  <div  v-if="isRideInformationEditMode" class="trip-submit-button">
                    <button @click="this.updateRideInformation" id="submit-btn2">שמירה</button>
                    <button @click="this.ReturnToSingleRide" style="background: #FFCC00;" id="submit-btn2">חזרה לטיול</button>
                  </div>

                  <div v-else class="trip-submit-button">
                    <button @click="this.createRideInformation" id="submit-btn2">המשך</button>
                  </div>

                  <!-- <div class="trip-submit-button">
                    <button @click="this.updateAttraction" id="submit-btn2">יציאה</button>
                  </div> -->

                </div>
              </div>
            </div>
          </div>
        </div>
    <PageFooter/>
  </div>
</template>


<script>
import PageFooter from "@/components/PageFooter.vue";
import flatPickr from "vue-flatpickr-component";
import SingleRideHeader from "@/components/SingleRideHeader";
import "flatpickr/dist/flatpickr.css";
import { mapActions, mapGetters } from 'vuex';

export default {
data() {
  return {
    value: "",
    value1: 2,
    value2: 2,
    value3: 0,
    text: "",
    // date: null,
  };
  },
  name: 'RideInformation',
    components: {
      PageFooter,
      flatPickr,
      SingleRideHeader,
    },
    computed: {...mapGetters([  'rideInformationTitle',
                                'rideInformationSherdPhotosAlbomLink',
                                'rideInformationText',
                                'rideInformationNumbrOfAdoult',
                                'rideInformationNumbrOfTeen',
                                'rideInformationNumbrOfChild',
                                'rideInformationPhysicalDisabilities',
                                'rideInformationCheckIn',
                                'rideInformationCheckOut',
                                'isRideInformationEditMode',
                                'getDatePickarFormat',
                                 ]),
      datePickerConfig:{
        get(){
          if( this.isRideInformationEditMode )
            return { dateFormat:'d-m-Y' }
          return { mode: 'range', dateFormat:'d-m-Y' }
        }
      },
      datePickerPlaceholder:{
        get(){
          if( this.isRideInformationEditMode )
            return "תאריך התחלה של הטיול"
          return "תאריך התחלה וסיום"
        }
      },
      localRideInformationTitle:{
        get () {
          return this.rideInformationTitle
        },
        set (value) {
          this.$store.commit('setRideInformationTitle', value )
        }
      },
      localRideInformationSherdPhotosAlbomLink:{
        get () {
          return this.rideInformationSherdPhotosAlbomLink
        },
        set (value) {
          this.$store.commit('setRideInformationSherdPhotosAlbomLink', value )
        }
      },

      localRideInformationText:{
        get () {
          return this.rideInformationText
        },
        set (value) {
          this.$store.commit('setRideInformationText', value )
        }
      },
      date:{
        get () {
          return this.rideInformationCheckIn

        },
        set (value) {
          this.$store.commit('setRideInformationCheckIn', value.split(' to ')[0])
          this.$store.commit('setRideInformationCheckOut', value.split(' to ')[1])

        },
      },
      physicalDisabilities:{
        get () {
          return this.rideInformationPhysicalDisabilities
        },
        set (value) {
          this.$store.commit('setRideRideInformationPhysicalDisabilities', value)
        }
      },
      numbrOfAdoult:{
        get () {
          return this.rideInformationNumbrOfAdoult
        },
        set (value) {
          this.$store.commit('setRiderideInformationNumbrOfAdoult', value)
        }
      },
      numbrOfTeen:{
        get () {
          return this.rideInformationNumbrOfTeen
        },
        set (value) {
          this.$store.commit('setRideRideInformationNumbrOfTeen', value)
        }
      },
      numbrOfChild:{
        get () {
          return this.rideInformationNumbrOfChild
        },
        set (value) {
          this.$store.commit('setRideRideInformationNumbrOfChild', value)
        }
      },
    },
    methods: 
    {
    ...mapActions([ 'register',
                    'authenticate',
                    'validateAndupdateAppToken',
                    'createRideInformation',
                    'updateRideInformation',
                    'ReturnToSingleRide', 
                    ] ),
    updateTitle ( value ) {
      this.$store.commit('setRideInformationTitle', value )
    },
    updateNumbrOfAdoult (e) {
      this.$store.commit('setRiderideInformationNumbrOfAdoult', e.target.value)
    },
    updateNumbrOfTeen (e) {
      this.$store.commit('setRideRideInformationNumbrOfTeen', e.target.value)
    },
    updateNumbrOfChild (e) {
      this.$store.commit('setRideRideInformationNumbrOfChild', e.target.value)
    },
    updatePhysicalDisabilities (e) {
      this.$store.commit('setRideRideInformationPhysicalDisabilities', e.target.value)
    },
    register () {
      this.$store.dispatch('register', { email: this.email, password: this.password })
        .then(() => this.$router.push('/'))
    }
  },
};
</script>


<style scoped>
.after_page_header{
  margin-top: 20rem;
}

.expand-arrow {
  background: #fff
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' %3E%3Cpath d='M 44.988281 13.984375 C 44.726563 13.992188 44.476563 14.101563 44.292969 14.292969 L 25 33.585938 L 5.707031 14.292969 C 5.519531 14.097656 5.261719 13.992188 4.992188 13.988281 C 4.582031 13.992188 4.21875 14.238281 4.0625 14.613281 C 3.910156 14.992188 4 15.421875 4.292969 15.707031 L 24.292969 35.707031 C 24.683594 36.097656 25.316406 36.097656 25.707031 35.707031 L 45.707031 15.707031 C 46.003906 15.421875 46.09375 14.980469 45.9375 14.601563 C 45.777344 14.222656 45.402344 13.976563 44.988281 13.984375 Z' %3E%3C/path%3E%3C/svg%3E")
    no-repeat;
  padding: 10px;
  margin-left: 10px;
}
.dropdown-toggle h3{
  
  font-size: 18px;
  font-weight: 700;
  color: rgba(13, 47, 41, 0.5);
}
.dropdown-menu h4{
  display: block!important;
}
input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  padding: 10px;
}











.input-errors{
  color: rgba(255, 0, 0, 1);
  font-size: 16px;
  padding: 0 1rem;
}

.container {
  padding: 0 1.5rem ;
}

.row {
  flex-direction: row-reverse;
}

/* activity page */
.trip-card-section {
  padding: 255px 0 50px;
}
.trip-card-top {
  text-align: center;
  margin-bottom: 50px;
}
a.trip-btn-bg {
  display: inline-block;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  background: #07A0C3;
  border-radius: 45px;
  padding: 9px 38px;
  margin-right: 50px;
}
a.trip-btn-outline {
  background: #FFFFFF;
  border: 1px solid #07A0C3;
  border-radius: 45px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: rgba(13, 47, 41, 0.5);
  display: inline-block;
  width: 500px;
  padding: 6px 38px;
}
a.trip-btn-outline img{
  margin-left: 10px;
}
.trip-map-four {
  position: sticky;
  top: 237px;
}

.trip-card2-thumb {
  height: 232px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F7F7F7;
}
.trip-card2-thumb img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}
.trip-card2-thumb.plus img{
  width: auto;
  height: auto;
}

.trip-card2-wrap {
  margin: 0 25px;
}
.trip-card2-data {
  background: rgba(13, 47, 41, 0.3);
  text-align: center;
  padding: 16px;
  transition: all 0.4s;
}
.col-lg-4.col-sm-6:nth-child(1) .trip-card2-data{
  background: rgba(13, 47, 41, 0.3);
}
.col-lg-4.col-sm-6:nth-child(2) .trip-card2-data{
  background: #07A0C3;
}
.col-lg-4.col-sm-6:nth-child(3) .trip-card2-data{
  background: #0AD6A1;
}
.trip-card2-wrap:hover .trip-card2-data{
  background-color: #07A0C3;
}
.trip-card2-data h3{
  font-weight: 700;
  font-size: 18px;
  color: #FFFFFF;
}

.trip-activity-section {
  padding: 30px 0 80px;
}
.trip-activity-section .trip-sidebar {
  margin-left: 100px;
}

.trip-activity-section .trip-card-content {
  padding-left: 10px;
}
.trip-activity-section .trip-card-footer{
  padding: 0;
}
a.trip-card-btn {
  background: rgba(13, 47, 41, 0.5);
  border-radius: 45px;
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding: 6px 19px;
  transition: all 0.4s;
}
a.trip-card-btn:hover{
  background-color: #0A816D;
}
.trip-activity-section .trip-card-wrap {
  border: 5px solid #000;
  transition: all 0.4s;
  margin-bottom: 30px;

}
.trip-activity-section .trip-card-wrap:hover {
  border: 5px solid #07A0C3;

}
.trip-location {
  margin-right: 143px;
}
.trip-location ul li{
  font-size: 20px;
  color: #0D2F29;
  position: relative;
  margin-bottom: 15px;
  padding-right: 35px;
  text-align: right;
}
.trip-location ul li img{
  width: 22px;
  position: absolute;
  right: 0;
}

.trip-activity-top h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #0D2F29;
  border-bottom: 2px solid #0D2F29;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.trip-activity-map {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.trip-activity-map h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: rgba(13, 47, 41, 0.5);
  margin: 0;
  margin-right: 20px;
}
.trip-activity-map h6 img{
  margin-left: 7px;
}
.trip-activity-map h5 {
  width: 120px;
  height: 24px;
  background: #000000;
  border: 1px solid rgba(13, 47, 41, 0.5);
  border-radius: 45px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  margin: 0;
}

.trip-contnet-section.mobile-device{
  display: none;
}

.trip-location.mobile-device{
  display: none;
}
.trip-hero-section.activity-page {
  padding: 120px 0 55px;
}
/* modal */
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 45px 55px;
}
.modal-dialog {
  max-width: 310px;

  margin: 1.75rem auto!important;
  margin-top: 10%!important;

}
.modal-content {
  background: #FFFFFF;
  box-shadow: 4px 4px 4px rgb(0 0 0 / 10%);
  border-radius: 25px;
}
.trip-login-wrap{
  text-align: center;
}
.trip-login-field{
  margin-bottom: 10px;
}
.trip-login-field input{
  height: 30px;
  width: 100%;
  text-align: right;
  border: 1px solid #C1C0C0;
  border-radius: 22px;
  font-size: 12px;
  line-height: 14px;
  padding: 5px 15px!important;

  color: #C1C0C0;
}
button#trip-login-btn,
button#trip-login-btn2 {
  width: 100%;
  height: 30px;
  background: #34D0B6;
  border-radius: 22px;
  border: none;
  font-weight: 500;
  font-size: 12px;
  color: white;
  margin-top: 15px;
}
button#trip-login-btn2{
  background-color:  #07A0C3;
}

.trip-login-wrap h3{
  font-weight: 800;
  font-size: 18px;
  line-height: 21px;
  color: #2E2E3A;
  margin-bottom: 50px;
  text-align: center
}
.trip-border-content{
  text-align: center;
  margin-top: 20px;
  margin-bottom: 12px;
}
.trip-border-content p{
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #2E2E3A;
  text-align: center;
  position: relative;
  z-index: 0;
  display: inline-block;
  background-color: #fff;
}
.trip-border-content p:before,
.trip-border-content p:after {
  content: " ";
  left: -60px;
  top: 7px;
  width: 87%;
  height: 1px;
  display: inline;
  position: absolute;
  background-color: #2E2E3A;
  z-index: -1;
}
.trip-border-content p:after{
  left: auto;
  right: -60px;
}
.trip-login-bottom-btn{
  margin-top: 25px;
}
.trip-login-bottom-btn h5{
  font-size: 10px;
  line-height: 12px;
  color: #2E2E3A;
  margin-bottom: 5px;
}

button.trip-login-btn {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
  width: 129px;
  height: 30px;
  background: #07A0C3;
  border-radius: 22px;
  border: none;
}
.trip-login-check {
  text-align: right;
}
.trip-login-check h5{
  font-size: 9px;
  line-height: 11px;
  text-align: right;
  color: #2E2E3A;
  margin-top: 7px;
}
.trip-login-check label{
  font-size: 10px;
  line-height: 12px;
  color: #2E2E3A;
}
.trip-login-check input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.trip-login-check label {
  position: relative;
  cursor: pointer;
}

.trip-login-check label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #2E2E3A;
  padding: 0 6.5px;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-left: 5px;
  border-radius: 3px;
}

.trip-login-check input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  right: 6px;
  width: 5px;
  height: 8px;
  border: solid #2E2E3A;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
/* contact page */
.trip-card-top.trip-card-top2{
  text-align: left;
  position: relative;
}
.trip-card-top h4 {
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  color: #0D2F29;
  display: inline-block;
  position: absolute;
  top: 0;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
}
.trip-contact-wrap h4{
  display: none;
}
.trip-contact-field {
  margin-bottom: 15px;
}
.trip-contact-field label{
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  direction: ltr;
  color: rgba(13, 47, 41, 0.5)!important;
;
  display: block;
  margin-bottom: 5px;
  margin-right: 40px;
  }
  .trip-contact-field label span{
    color: #E42424;
  }

  .trip-contact-input{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .trip-contact-field img{
    width: 100%;
  }
  .trip-contact-input input,
  .trip-contact-input textarea{
    height: 56px;
    width: 100%;
    background: #FFFFFF;
    border: 2px solid #07A0C3!important;
    border-radius: 45px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: right;
    padding: 10px 20px!important;
    color: black !important;

  }
  .trip-contact-input textarea{
    height: 220px;
    resize: none;
  }
  .trip-input-icon {
    position: absolute;
    top: 13px;
    right: 25px;
  }
  .trip-contact-input p{
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin:15px 0 0;
    color: rgba(228, 36, 36, 0.6)
  }
.trip-contact-input input::-webkit-input-placeholder,
.trip-contact-input textarea::-webkit-input-placeholder{ /* WebKit, Blink, Edge */
    color: rgba(13, 47, 41, 0.5);
    opacity: 1;
}
.trip-contact-input input:-moz-placeholder,
.trip-contact-input textarea:-moz-placeholder{ /* Mozilla Firefox 4 to 18 */
  color: rgba(13, 47, 41, 0.5);
   opacity:  1;
}
.trip-contact-input input::-moz-placeholder,
.trip-contact-input textarea::-moz-placeholder{ /* Mozilla Firefox 19+ */
  color: rgba(13, 47, 41, 0.5);
   opacity:  1;
}
.trip-contact-input input:-ms-input-placeholder,
.trip-contact-input textarea:-ms-input-placeholder{ /* Internet Explorer 10-11 */
  color: rgba(13, 47, 41, 0.5);
   opacity: 1;
}
.trip-contact-input input::-ms-input-placeholder,
.trip-contact-input textarea::-ms-input-placeholder{ /* Microsoft Edge */
  color: rgba(13, 47, 41, 0.5);
   opacity: 1;
}

.trip-contact-input input::placeholder,
.trip-contact-input textarea::placeholder{ /* Most modern browsers support this now. */
  color: rgba(13, 47, 41, 0.5);
   opacity: 1;
}

button#submit-btn1 {
  height: 56px;
  background: #0D2F29;
  border-radius: 45px;
  width: 100%;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #FFFFFF;
}
button#submit-btn2 {
    width: 188px;
    height: 42px;
    background: #DD6E42;
    border-radius: 45px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    border: none;
    margin: 0 auto;
    transition: all 0.4s;
}

.trip-submit-button{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 50px;
  text-align: center;
}

.trip-contact-form-section {
  padding-bottom: 150px;
}
.trip-contact-siderbar{
  background: rgba(225, 246, 251, 0.5);
  border-radius: 18px;
  padding: 25px;
  margin-left: 50px;
}
.trip-contact-siderbar h5{
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #E42424;
}

.trip-card2-thumb-resposive {
  display: none;
}
.trip-card2-thumb-resposive img{
  width: 100%;
  height: 100%;
}

.close-icon {
  border: 1px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
  position: absolute;
  left: 13px;
  top: 13px;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  color: #FF0000;
}
.close-icon img{
  width: auto;
}
.input-remove:not(:valid) ~ .close-icon {
	display: none;
}


/* updloade img */


.btn_upload {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  padding: 5px 10px;
  height: 198px;
  background: #FFFFFF;
  border: 2px solid #07A0C3;
  border-radius: 45px;
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  color: #0D2F29;
  padding-right: 66px;
  padding-top: 13px;
}

.btn_upload:hover,
.btn_upload:focus {
  background-color: none;
}


.btn_upload input {
  cursor: pointer;
  height: 100%!important;
  position: absolute;
  filter: alpha(opacity=1);
  -moz-opacity: 0;
  opacity: 0;
  left: 0;
  top: 0;
  z-index: 99;
}


.preview1.it {
  position: absolute;
  top: 29px;
  width: 150px!important;
  height: 150px;
  left: 50%;
  transform: translateX(-50%);
}

.btn-rmv1{
  display: none;
}

.rmv {
  cursor: pointer;
  color: #fff;
  border-radius: 30px;
  display: inline-block;
  position: absolute;
  right: 38%;
  top: 34px;
  z-index: 999;
  border: navajowhite;
  background-color: transparent;
  font-size: 32px;
}
img#ImgPreview {
  position: absolute;
  text-indent: 66em;
}













/*For bigger screen then normal 1500px desktop*/

@media (min-width: 1500px) {
    .container {
      max-width: 1360px;
    }
  }
  @media only screen and (max-width: 1399px) {
    .main-menu ul li{
        margin: 0 14px;
    }
    .main-menu ul li a{
        font-size: 16px;
    }
  }

  @media (min-width: 991px) {
      header.trip-header-section.sticky-menu {
          opacity: 0;
        }
        .trip-hero-section.sticky-menu{
          padding: 20px 0 0;
        }
    }

  @media (min-width: 1200px) and (max-width: 1499px) {
      .trip-sidebar{
          margin: 0;
      }

      .trip-activity-section .trip-sidebar {
          margin-left: 0;
      }
      a.trip-card-btn {
          padding: 6px 15px;
      }

  }


  @media only screen and (max-width: 1199px) {
      a.trip-header-social {
          display: none;
      }
      .main-menu ul li {
          margin: 0 15px;
      }
      .main-menu {
          margin-right: 30px;
      }
      .trip-sidebar{
          margin: 0;
      }
      .trip-card-footer {
          padding-left: 20px;
      }

      a.trip-card-btn {
          padding: 5px 10px;
      }
      .trip-location {
          margin-right: 0;
      }
      .trip-activity-section .trip-sidebar {
        margin-left: 30px;
    }
    .trip-card2-wrap {
        margin: 0 10px;
    }
    .trip-card2-data h3 {
        font-size: 16px;
    }

  }



  /*For Tablet Layout*/
  @media(max-width: 991px) {
      .main-menu {
          display: none;
      }
      .offcanves-menu{
          display: block;
      }
      .menu-bar{
          display: block;
      }
      .trip-menu-wrap {
          display: block;
          text-align: left;
          margin-left: 50px;
      }
      .trip-card2-wrap {
          margin: 0;
      }
      .trip-card2-data h3 {
          font-size: 18px;
      }
      a.trip-btn-outline {
          width: 400px;
      }

      .trip-location {
          display: none;
      }

  .trip-location.mobile-device{
      display: block;
      text-align: center;
    }
    .trip-location.mobile-device ul li{
      margin: 0 19px;
      font-size: 16px;
      display: inline-block;
    }
    .trip-activity-map {
      justify-content: center;
      margin-bottom: 14px;
      margin-top: 30px;
  }
  .trip-activity-section .trip-sidebar {
      margin-left: 0;
  }

  .trip-card-section {
      padding: 220px 0 50px;
  }


  header.trip-header-section {
      padding: 18px 0;
      top: 0;
      background-color: white;
  }



  /* activity */
  .trip-hero-section.activity-page {
      padding: 109px 0 30px;
      margin-top: 0;
  }
  .trip-map-four {
      display: none;
  }

  .trip-hero-section.trip-page {
      padding: 110px 0 35px;
      margin: 0;
  }
  .trip-hero-section.home2 {
      margin: 0;
  }
  .trip-hero-section.home2 {
      padding: 95px 0 20px;
  }
  .trip-date-section.home2 {
      padding: 40px 0;
  }
  .trip-iconbox-section {
      padding: 60px 0 30px;
  }
  .trip-iconbox-wrap {
      margin-bottom: 30px;
  }

  .trip-card2-wrap {
    margin: 0 0 15px;
  }
  .trip-contact-siderbar{
    margin: 0 0 10px;
  }

  }


  /*For Wide Mobile Layout*/
  @media (max-width: 767px) {
      a.trip-header-btn {
          margin-right: 0;
      }
      .trip-logo {
          display: inline-block;
          width: 150px;
      }
      .trip-card-body {
          display: block;
      }
      .trip-card-thumb img{
          width: 100%;
      }
      .trip-card-thumb{
          margin-bottom: 20px;
      }

      a.trip-btn-outline {
          width: 270px;
      }
      .trip-card2-data h3 {
        font-size: 10px;
    }
    .trip-card2-thumb.plus img{
        width: 48px;
        height: 48px;
    }


    /* contact page */
    .trip-card-top h4{
        opacity: 0;
    }
    .trip-contact-wrap h4 {
        display: block;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #0D2F29;
    }

    .trip-card-top.trip-card-top2{
        margin: 0;
    }
    .trip-card-section {
        padding: 220px 0 20px;
    }

    .trip-contact-siderbar h5 {
        font-size: 12px;
        line-height: 16px;
    }
    .trip-contact-siderbar {
        padding: 10px 50px;
    }
    .trip-contact-field label {
        font-size: 12px;
    }
    .trip-contact-input input,
    .trip-contact-input textarea {
        height: 30px;
        font-size: 12px;
        padding: 10px 30px!important;

    }
    .trip-contact-input textarea{
        height: 94px;
        border-radius: 15px;
    }
    .trip-input-icon {
        top: 3px;
        right: 15px;
    }
    .trip-input-icon img{
        width: 12px;
    }

    .trip-contact-input p {
        font-size: 12px;
        margin: 6px 0 0;
    }
    button#submit-btn1 {
        height: 30px;
        font-size: 12px;
        line-height: 21px;
    }
    button#submit-btn2 {
        height: 30px;
        font-size: 14px;
    }
    .trip-submit-button {
        margin-top: 15px;
    }
    .trip-contact-form-section {
        padding-bottom: 30px;
    }

    .close-icon {
        top: 2px;
        font-size: 12px;

    }
    .close-icon img{
        width: 12px;
    }
    .btn_upload {
        font-size: 12px;

    }
    .btn_upload {
        height: 100px;
        padding-right: 40px;
    }
    .preview1.it {
        top: 22px;
        width: 60px!important;
        height: 60px;
    }
    .rmv {
        right: 44%;
        top: 21px;
        font-size: 12px;
    }
    .trip-input-icon.trip-input-icon2 {
        top: 12px;
        right: 22px;
    }


  }

  @media (max-width: 667px) {
      .trip-hero-section {
          padding: 5px 0 13px;
      }

      .responsive-width-left{
          width: 30%;
      }
      .responsive-width-right{
          width: 70%;
      }
      .trip-hero-content h1 {
          font-size: 16px;
          margin-bottom: 3px;
      }
      .map-icon {
          width: 20px;
          margin-left: 5px;
      }
      .trip-social ul li a img{
          width: 40px;
      }

  .trip-contnet-section{
      display: none;
  }

  .menu-bar {
      color: #000;
  }

  .trip-card-section {
      padding: 175px 0 30px;
  }
  a.trip-btn-bg {
      font-size: 12px;
      padding: 5px 30px;
      margin-right: 20px;
  }
  a.trip-btn-outline {
      width: 170px;
      padding: 3px 38px;
      font-size: 14px;
  }
  a.trip-btn-outline img {
      margin-left: 10px;
      width: 17px;
  }
  .trip-card-top {
      text-align: center;
      margin-bottom: 20px;
  }

  .trip-card2-data {
      padding: 7px;
  }
  .trip-card2-thumb{
    display: none;
  }
  .trip-activity-top h4 {
      font-size: 16px;
      margin-bottom: 15px;
      padding-bottom: 2px;
  }
  .trip-location.mobile-device ul li {
      margin: 0 7px;
      font-size: 12px;
      padding-right: 20px;
  }
  .trip-location ul li img {
      width: 15px;
  }
  .trip-activity-map {
      margin-top: 15px;
  }
  .trip-card-header h4 {
      font-size: 16px;
  }
  .trip-card-content p {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  a.trip-card-btn {
      padding: 3px 14px;
      font-size: 12px;
  }
  .trip-card2-thumb {
    height: 130px;
}


  /* footer */
  footer.trip-footer-section {
      padding: 14px 0;
  }
  .footer-menu ul li {
      padding: 0;
      display: block;
      line-height: 1;
  }
  .footer-menu ul li a{
      font-size: 12px;
  }
  .footer-menu ul li:before {
      content: none;
  }
  .trip-footer-wrap img {
      margin-bottom: 9px;
      width: 110px;
  }

  /* ctivity */
  .trip-hero-section.activity-page {
      padding: 95px 0 15px;
  }
  .trip-sidebar-item-top {
      display: flex;
      text-align: center;
      justify-content: space-between;
  }
  .trip-sidebar-item-top.desktop{
      display: none;
    }
    .trip-sidebar-item-top.mobile {
      display: flex;
  }

  .trip-cta2-section {
      padding: 30px 0 10px;
  }
  .trip-cta2-wrap {
      margin-bottom: 20px;
  }
  .trip-cta2-title h2 {
      font-size: 14px;
      margin-bottom: 30px;
  }
  .trip-cta2-data p {
      font-size: 14px;
      line-height: 18px;
  }
  .trip-cta2-thumb img {
      width: 100px;
      height: 100%;
  }

  .trip-iconbox-thumb {
      height: auto;
  }
  .trip-iconbox-thumb img {
      width: 100px;
  }
  .trip-iconbox-data h3 {
      font-size: 14px;
      line-height: 18px;
  }
  .trip-iconbox-data p {
      font-size: 12px;
      line-height: 20px;
  }
  .trip-social ul li {
      margin: 0 12px;
  }
  .trip-login-wrap h3 {
    margin-bottom: 20px;
}
.trip-card2-thumb.plus {
    height: 90px;
    display: flex;
}
.trip-card2-thumb-resposive {
    display: block;
}


  }

  @media (max-width: 575px) {
    .responsive-width{
        width: 50%;
        margin: 0 auto;
    }
    .trip-card2-data h3 {
        font-size: 9px;
    }

  }

  /*For 320-384px up Mobile Layout*/
  @media (max-width: 479px) {

    .trip-card-footer {
          padding-left: 0;
      }
      .trip-card-body {
          padding: 7px 10px 20px;
      }

      .trip-card-header {
          display: block;
          text-align: center;
      }
      .trip-card-header h4 {
          margin-top: 15px;
      }

      a.trip-hero-btn {
          font-size: 12px;
      }

  }
  @media (max-width: 400px){
      .trip-menu-wrap {
          margin-left: 25px;
      }
  }






.trip-input-field{
  margin-bottom: 30px;
}
.trip-input-field input,
.trip-input-field textarea{
  font-weight: 500;
  font-size: 18px;
  text-align: right;
  color: #0D2F29;
  width: 100%;
  height: 60px;
  padding: 20px 40px!important;
  border: none!important;
  background: #FFFFFF;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 22px;
}
.trip-input-field textarea{
  height: 312px;
}


/* new css add */
input#physical-disabilities {
    width: 13px;
    cursor: pointer;
}

.flatpickr-input[readonly]{
  border-radius: 50px;
  font-weight: 600;
  direction: ltr;
  font-size: 18px;
  padding: 5px 20px;  
  padding-right: 60px!important;
  color: rgba(13, 47, 41, 0.5)!important;
  border: 2px solid #07A0C3!important;
}

.normal_image, 
.trip-input-field{
  position: relative;
}

img.coulunder_image {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    right: 20px;
}

.trip-contact-input .dropdown.b-dropdown{
    width: 100%;
    border-radius: 50px;
    border: 2px solid #07A0C3!important;
}
.date_and_participent_box .dropdown.b-dropdown {
  border-radius: 50px;
  border: 2px solid #07A0C3!important;
}
.b-form-spinbutton{
  border: 1px solid #07A0C3!important;
}
.border-left {
    border-left: 1px solid #07A0C3!important;
}

.border-right {
    border-right: 1px solid #07A0C3!important;
}

@media (max-width: 991px) {
  .normal_image {
    display: block;
}
.flatpickr-input[readonly] {
    width: 100%;
}

}
@media (max-width: 767px) {
  .flatpickr-input[readonly] {
    font-size: 12px;
    padding: 0px 20px;
    width: 100%;
    height: 28px;
    padding: 5px 50px 5px 20px!important;
}
img.coulunder_image {
    width: 18px;
}

.dropdown-toggle h3 {
    font-size: 13px;
}
.date_and_participent_box.add_ride_boxes{
  padding: 15px;
}
.main_add_ride {
    margin: 2.5rem;
    padding: 2.5rem;
}
}


</style>