<template>
  <div>
    <!-- signup modal -->
    <OldSignup v-model="modals.oldSignup" @showmodal="showModal($event)" />
    <!-- login form -->
    <LoginForm v-model="modals.loginForm" @showmodal="showModal($event)" />
    <!-- signup Form -->
    <SignupForm v-model="modals.signupForm" @showmodal="showModal($event)" />
    <!-- reset form -->
    <ResetForm v-model="modals.resetForm" @showmodal="showModal($event)" />

    <MainLogIn v-model="modals.mainLogIn" />
    <MainRegistration v-model="modals.mainRegistration" />
    <ShareRide v-if="allRide.ride" v-model="modals.shareRide" />
    <Attraction  v-model="modals.attraction" />
    <UpdateAttractionTime v-if="allRide.ride" v-model="modals.updateAttractionTime" />
    <PleaseAddTheRideToYourList v-model="modals.pleaseAddTheRideToYourList" />
    <PleaseLogInInOrderToEditRide v-model="modals.pleaseLogInInOrderToEditRide" />
    <AreYouShureRemovingAttraction v-model="modals.areYouShureRemovingAttraction" />
    <DynamicModal v-model="modals.dynamicModal" />
    <LoadingIcon v-model="modals.loadingIcon" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
// import Login from "@/components/Modals/Login";
import OldSignup from "@/components/Modals/OldSignup";
import LoginForm from "@/components/Modals/LoginForm";
import SignupForm from "@/components/Modals/SignupForm";
import ResetForm from "@/components/Modals/ResetForm";
import ShareRide from "@/components/Modals/ShareRide";
import Attraction from "@/components/Modals/Attraction";
import UpdateAttractionTime from "@/components/Modals/UpdateAttractionTime";
import LoadingIcon from "@/components/Modals/LoadingIcon";
import MainLogIn from "@/components/Modals/MainLogIn";
import MainRegistration from "@/components/Modals/MainRegistration";
import PleaseAddTheRideToYourList from "@/components/Modals/PleaseAddTheRideToYourList";
import PleaseLogInInOrderToEditRide from "@/components/Modals/PleaseLogInInOrderToEditRide";
import AreYouShureRemovingAttraction from "@/components/Modals/AreYouShureRemovingAttraction";
import DynamicModal from "@/components/Modals/DynamicModal";

export default {
  name: "PagePopups",
  computed: mapGetters(["modals", "allRide"]),
  methods: {
    ...mapActions(["showModal"])
  },
  components: {
    MainLogIn,
    MainRegistration,
    OldSignup,
    LoginForm,
    SignupForm,
    ResetForm,
    ShareRide,
    Attraction,
    UpdateAttractionTime,
    PleaseAddTheRideToYourList,
    PleaseLogInInOrderToEditRide,
    AreYouShureRemovingAttraction,
    DynamicModal,
    LoadingIcon
  }
};
</script>
<style scoped>

.light-bg {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
}
</style>
