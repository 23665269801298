import config from '../../common/config';

const state = {
  // center: { lat: 45.508, lng: -73.587 },
  // markers: [],
  // placesToSet: [],
  // currentPlace: null,
  // appMap: null,
};
const getters = {
  // center: state => state.center,
  // markers: state => state.markers,
  // placesToSet: state => state.placesToSet,
  // currentPlace: state => state.currentPlace,
  // appMap: state => state.appMap,
};
const actions = {
  addMarkerByLatAndLng: ( _, mapAndPoint) => {
    if( ! mapAndPoint.map ){ return; }
    if( ! mapAndPoint.point.latPoint ){ return; }
    var markLockation = new google.maps.LatLng(
      parseFloat( mapAndPoint.point.latPoint),
      parseFloat( mapAndPoint.point.lngPoint));  
    var name = '';
    // if( 'name' in mapAndPoint.point ){
    //   name = mapAndPoint.point.name
    // }
    // set the default
    var iconUrl =  config.config.MAP_POINT_WITH_COLLOR[0].IMAGE_URL
    if( 'iconUrl' in mapAndPoint.point ){
      iconUrl=mapAndPoint.point.iconUrl
    }
    var myImage = {
      url: iconUrl,
      size: new google.maps.Size(33, 33),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(15, 40),
    };

    var marker = new google.maps.Marker({
      position: markLockation,
      map: mapAndPoint.map,
      draggable: false,
      label: name,
      icon: myImage,
    })
    if( mapAndPoint.point.bounce ){
      if (marker.getAnimation() !== null) {
          marker.setAnimation(null);
         }
         marker.setAnimation( mapAndPoint.point.bounce );
    }

    mapAndPoint.rideMarkers.push( marker );
  },
  removeAllMarkers: ( _, markers) =>{
    while (markers.length > 0) {
      markers.pop().setMap(null);
    } 
  },

  fitBoundesToMarkers: ( _, mapContiner) => {
    if(!mapContiner.appMap){
      return;
    }
    if(mapContiner.markers.length == 0){
      return;
    }
    var bounds = new google.maps.LatLngBounds();
    var i;
    for( i=0; i < mapContiner.markers.length; i++ ){
      bounds.extend(mapContiner.markers[i].getPosition());
    }
    if (mapContiner.markers.length > 1) {
      mapContiner.appMap.fitBounds(bounds);
    }
    else if (mapContiner.markers.length == 1) {
      mapContiner.appMap.setCenter(bounds.getCenter());
      mapContiner.appMap.setZoom(12);
    }
  },
}
const mutations = {
  // setCurrentPlace( state, place ) {
  //   state.currentPlace = place;
  // },
  // setAppMap( state, appMap ) {
  //   state.appMap = appMap;
  // },
  // addMarker( state, marker ) {
  //   state.markers.push( marker );
  // },
  // addPlace( state, place ) {
  //   state.placesToSet.push( { place } );
  // },
  // setCenter( state, center ) {
  //   state.center =  center;
  // },
};

export default {
  state,
  getters,
  actions,
  mutations
};
