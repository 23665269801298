<template>
  <main>
    <section v-if='rideHeader.ride' >     
      <div class="centering_with_gap flex rem-padding-right">
        <div class="centering_with_gap flex left_border_blue">
          <div class="field">
            <img 
             :src="require('@/assets/images/calendar_icon.png')"
             width="21px"
             height="24px"
             alt=""
            >
          </div>            
          <div class="field">
            <i class="calendar icon"></i>
            <span class="main_ride_dates">{{rideHeader.ride.end_date}}</span>
          </div>
          <img
            :src="require('@/assets/images/arrow-right-solid.png')"
            alt=""
            width="18px"
            height="20px"
          >
          <div class="field">
            <i class="calendar icon"></i>
            <span class="main_ride_dates">{{rideHeader.ride.start_date}}</span>
          </div>
        </div>
        <div class="centering_with_gap flex">
          <img 
            :src="require('@/assets/images/users_icon.png')"
            alt=""
            width="30px"
            height="24px"
            >
          <span class="main_ride_dates">{{numberOfParticipentText()}}</span>
        </div>
      </div>
      <PresentUserInfo/>
      <div class="trip-date-section">
        <div class="container">
          <div class="trip-textarea">
            <p class="ride-detailes">
              {{rideHeader.ride.text}}
            </p>
            <p 
              v-if="doWeHaveUserPage(rideHeader.ride.authorId)" 
              @click="goToUserPage( rideHeader.ride.authorId )"
              class="user-more-rides text-center">
              לטיולים נוספים של <br>
              <span class="user-more-rides-btm btn btn-primary rounded-pill primary border-0">{{rideHeader.ride.authorName}}</span>
            </p>
            <p 
              v-else-if="rideHeader.ride.authorName" 
              @click="goToUserPage( rideHeader.ride.authorId )"
              class="user-more-rides text-center">
              הטיול שותף על ידי:  {{rideHeader.ride.authorName}}
            </p>
					</div>
					<div 
            v-if="allRide && allRide.highLevelRideLocations && allRide.highLevelRideLocations.length"
            class="trip-timeline-section">
            <h3 class="text-center main-ride-titles">מקומות לינה</h3>
            <div class="row">
              <div class="hotels-sumary centering_line_with_gap flex padding-right-1_5rem">
								<div class="centering_with_gap padding-right-1_5rem" >
                  <div
                    v-for="(star, index) in allRide.highLevelRideLocations" 
                    @click="scrollMeTo(star.id + '_acommodation_ref_id')"
                    :key="star.id"
                    :class="[(index % 2 === 0) ? 'd-flex timeline-odd-location' : 'd-flex timeline-even-location',
                              { 'timeline-last-item': index === allRide.highLevelRideLocations.length - 1 }
                               ]"
                    >
                    <div
                      :class="[(index % 2 === 0) ? 'position-absolute timeline-odd-text' : 'position-absolute timeline-even-text']"
                      >
                      <p class="timeline-location-name">{{star.locationName}}</p>
                      <p 
                        v-if="star.numberOfDays > 1"
                        class="timeline-location-duration">
                        {{star.numberOfDays}} לילות
                      </p>
                      <p 
                        v-else
                        class="timeline-location-duration">
                        לילה אחד
                      </p>
                    </div>
                    <img 
                      alt="accommodationNumber"
                      class=""
                      width="211"
                      height="113"
                      :src="getDayImageUrl(index)"
                      >
                  </div>
								</div>
							</div>
              <div class="hotels-sumary padding-left-1_5rem">
								<div class="trip-map-one">
                  <GlobalMap 
                    passed_class="ride_samery_map">
                  </GlobalMap>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
    </section>
  </main>
</template>



<script>
import GlobalMap from './GlobalMap';

import { mapGetters } from 'vuex';
import config from '@/common/config';
import PresentUserInfo from "@/components/PresentUserInfo";

export default {
    name: 'SingleRideSumary',
    components: {
      GlobalMap,
      PresentUserInfo
    },
    computed: mapGetters(['allRide',
                          'rideHeader',
                          ]),
    methods: 
    {
      scrollMeTo(refId){
        const element = document.getElementById(refId);
        if ( element ){
          const offset = 140; // Adjust this value to determine how much higher to scroll
          const topPosition = element.offsetTop - offset;
          window.scrollTo({ top: topPosition, behavior: 'smooth' });
        }
      },
      doWeHaveUserPage( authorId){
        return config.config.USER_PAGE_CONVERTER[ authorId ]
      },
      goToUserPage( authorId){
        window.location.href = config.config.USER_PAGE_CONVERTER[ authorId ].link

      },
      getDayImageUrl( i ){
        if( i < config.config.BLUE_MAP_POINT_WITH_NUMERATOR_AND_LINE.length )
          return config.config.BLUE_MAP_POINT_WITH_NUMERATOR_AND_LINE[i].IMAGE_URL
        else
          return config.config.BLUE_MAP_POINT_WITH_NUMERATOR_AND_LINE[ config.config.BLUE_MAP_POINT_WITH_NUMERATOR_AND_LINE.length -1 ].IMAGE_URL
      },
      numberOfParticipent(){
        if ( ! this.rideHeader )
          return 0;
        const globalRide  = this.rideHeader.ride;
        let numberOfPerticipent = 0 ;
        if( globalRide.number_of_child )
          numberOfPerticipent += globalRide.number_of_child;
        if( globalRide.number_of_teen )
          numberOfPerticipent += globalRide.number_of_teen;
        if( globalRide.number_of_adult )
          numberOfPerticipent += globalRide.number_of_adult;
        return numberOfPerticipent;
      },
      numberOfParticipentText(){
      if( this.numberOfParticipent() > 1 )
        return this.numberOfParticipent() + " מטיילים "
      else
        return "מטייל אחד" 
      },

    }
}

import '@/views/general.css';
import '@/views/queries.css';

</script>

<style scoped>

.clickable-item {
  cursor: pointer;
}

</style>
