<template>
  <div>
   <SingleRideHeader
      presentedText="עריכת פרטי לינה"
      shouldPresentLocation=1
    />
      <div class="trip-contnet1-section">
        <div
          v-if="accommodationFormFromBaseRide&&accommodationFormFromBaseRide.id"
          class="container">
          <div class="trip-content1">
            <h3>מומלץ להזמין לינה ל:{{accommodationFormFromBaseRide.check_out_date + ' - ' + accommodationFormFromBaseRide.check_in_date}}</h3>
            <h4>אזור: {{accommodationFormFromBaseRide.cuntry_and_city}}<img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Ficons%2Fmap-black_pointer.png?alt=media&token=890165e4-6fcc-46a4-af7f-b7891377dcad" alt=""></h4>
          </div>
        </div>
      </div>
      <!-- End content -->

      <div
        v-if="accommodationFormFromBaseRide&&accommodationFormFromBaseRide.id"
        class="trip-contnet2-section">
        <div class="container">
          <div class="trip-content2">
            <p>בית מלון של טיול הבסיס</p>
            <h2>
              {{accommodationFormFromBaseRide.name}}
            </h2>
          </div>
        </div>
      </div>
      <!-- End content -->


      <b-col cols="12" class="my-3">
        <b-button
          pill
          variant="outline-dark"
          class="px-5 py-3"
          @click="ReturnToSingleRide()"
          >חזרה לטיול</b-button
        >
      </b-col>
      <div class="trip-contact-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="trip-contact-wrap">
                <div class="trip-input-field">
                  <gmap-autocomplete
                    @place_changed="handleautoCompliteFormAccommodation"
                    :select-first-on-enter="true"
                    placeholder="מילוי אוטומטי של פרטי הלינה"
                    size=""
                  >
                  </gmap-autocomplete>
                </div>
                <div class="trip-input-field">
                  <b-form-input
                    :value="accommodationName"
                    autocapitalize="none"
                    @input="updateAccommodationName"
                    placeholder="שם"
                    size="lg"
                ></b-form-input>
                </div>
                <div class="trip-input-field">
                  <flat-pickr
                    v-model="date"
                    class="border-0 bg-white form-control datepicker text-right"
                    placeholder="צ'ק אין   ←   צ'ק אאוט"
                    :config="{ mode: 'range', dateFormat:'d-m-Y' }"
                  ></flat-pickr>
                </div>
                <div class="trip-input-field">
                  <textarea 
                    name="textarea"
                    placeholder="טקסט חופשי על מקום הלינה"
                    v-model="accommodationOpenText"
                    class="add_ride_boxes"
                    >
                  </textarea>
                </div>

                <div class="trip-input-field">
                <label for="file">נא להעלות תמונה</label>
                <input  type="file"
                        name="file"
                        accept="image/*"
                        class="border-0 shadow text-right form-control"
                        v-on:change="uploadAccommodationImage"
                        id="file">
              </div>
              <div class="trip-input-field">
                <h4 v-if="this.isUplowding()" >{{Math.round(this.uploadValue)}}%</h4>
                <img v-if="isAccommodationImage" class="preview" height="200" width="200" :src="accommodationImage">
              </div>
              <b-button v-b-toggle.collapse-1 variant="primary">פרטים נוספים</b-button>
              <b-collapse id="collapse-1" class="mt-2">
              <div class="trip-input-field">
                <b-form-input
                  :value="googleContryAndCity"
                  @input="updateAccommodationGoogleContryAndCity"
                  placeholder="שם אזור הלינה"
                  size="lg"
                ></b-form-input>
              </div>
              <div class="trip-input-field">
                  <b-form-input
                    :value="accommodationAddress"
                    @input="updateAccommodationAddress"
                    placeholder="כתובת"
                    size="lg"
                  ></b-form-input>
                </div>
                <div class="trip-input-field">
                  <b-form-input
                    :value="accommodationLink"
                    @input="updateAccommodationLink"
                    placeholder="קישור לאתר"
                    class="border-0 shadow text-right"
                    size="lg"
                  ></b-form-input>
                </div>
                <div class="trip-input-field">
                  <b-form-input
                    :value="accommodationBookingConfirmation"
                    @input="updateAccommodationBookingConfirmation"
                    placeholder="מספר אישור ההזמנה"
                    class="border-0 shadow text-right"
                    size="lg"
                  ></b-form-input>
                </div>
                <div class="trip-input-field">
                  <h5 class="border-0 text-right" > נא להכניס את מיקום הלינה למפה </h5>
                  <GlobalMapSerchAutoComplet
                    @handleserchPointChange="handleUpdateserchpoint" >
                  </GlobalMapSerchAutoComplet>
                  <GlobalMap 
                    @serchPointChange="handleUpdateserchpoint"
                    passed_class="single_ride_map" >
                  </GlobalMap>
                </div>
                </b-collapse>
              <div class="mt-5 text-right">
                  <p v-if="accommodationErrores.length">
                    <b>בבקשה תתקנו את השדות הבאים</b>
                    <ul>
                      <li style="color: rgba(255, 0, 0, 1)" v-for="( error, index ) in accommodationErrores" :key="index"> {{error}}</li>
                    </ul>
                  </p>
              </div>
              <div v-if="haveFormFromRide" class="mt-5 text-right">
                <a class="trip-default-btn btn-sky btn-blue" @click="this.updateTheAccommodation">שמירה</a>
                <a class="trip-default-btn btn-sky btn-blue" @click="ReturnToSingleRide()">חזרה לטיול</a>
                <a class="trip-default-btn btn-sky btn-red" @click="this.deleteTheAccommodation">מחיקת הלינה</a>
              </div>
              <div v-else class="mt-5 text-right">
                  <a class="trip-default-btn btn-sky btn-green" @click="this.createAccommodation">שמירה</a>
                  <a class="trip-default-btn btn-sky btn-blue" @click="ReturnToSingleRide()">חזרה לטיול</a>
              </div>
          </div>
            </div>
            <div 
              class="col-lg-6"
              v-if="accommodationFormFromBaseRide&&accommodationFormFromBaseRide.id">
              <div class="trip-contact-left">
                <div class="trip-map-three">
                  <ins
                  class="bookingaff"
                  data-aid="2186348"
                  data-target_aid="2186348"
                  data-prod="map"
                  data-width="100%"
                  data-height="500"
                  data-lang="he"
                  data-dest_id="0"
                  data-dest_type="landmark"
                  :data-latitude=accommodationFormFromBaseRide.latitude
                  :data-longitude=accommodationFormFromBaseRide.longtitude
                  data-mwhsb="0"
                  :data-checkin=accommodationFormFromBaseRideCheckinFormatedToBoking
                  :data-checkout=accommodationFormFromBaseRideCheckoutFormatedToBoking
    
                >
                <a href="//www.booking.com?aid=2186348">Booking.com</a>
            </ins>
                </div>
                <!-- <div class="trip-contact-thumb">
                  <img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fabout.png?alt=media&token=a4db5c6e-fe98-47fa-a731-9fe5a7e4a968" alt="">
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End contact section -->
    <PageFooter />
  </div>
</template>

<script>

import GlobalMap from '@/components/GlobalMap';
import { mapActions, mapGetters } from 'vuex';
import PageFooter from "@/components/PageFooter.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import firebase from 'firebase'
import SingleRideHeader from "@/components/SingleRideHeader";
import GlobalMapSerchAutoComplet from '@/components/GlobalMapSerchAutoComplet';


export default {
  name: 'RideAccommodation',
  components: {
    SingleRideHeader,
    GlobalMap,
    PageFooter,
    flatPickr,
    GlobalMapSerchAutoComplet

  },
  data() {
    return {
      uploadValue: 0,
      value: "",
      text: "sdfsdfasdfsdfs",
      lat: 48.2220046,
      lng: 16.3738,
      flatPickrActive: {
        mode: "range",
      },
      flatPickrDisabled: {
        mode: "range",
        enableTime: true,
        defaultDate: ["2019-10-10 10:00", "2019-10-20 13:00"],
      },
    };
  },
  computed: {...mapGetters([  'allRide',
                              'email',
                              'password',
                              'accommodationName',
                              'accommodationAddress',
                              'googleContryAndCity',
                              'accommodationText',
                              'accommodationBookingConfirmation',
                              'accommodationLink',
                              'accommodationLat',
                              'accommodationLng',
                              'accommodationImage',
                              'isAccommodationImage',
                              'accommodationCheckIn',
                              'accommodationCheckOut',
                              'isAccommodationEditMode',
                              'accommodationFormFromRide',
                              'haveFormFromRide',
                              'haveFormFromRideAndLocation',
                              'accommodationFormFromBaseRide',
                              'accommodationFormFromBaseRideCheckinFormatedToBoking',
                              'accommodationFormFromBaseRideCheckoutFormatedToBoking',
                              'haveFormFromBaseRide',
                              'accommodationErrores', ]),
    date:{
      get () {
        return  this.accommodationCheckIn + ' to ' + this.accommodationCheckOut;

      },
      set (value) {
        this.$store.commit('setAccommodationCheckIn', value.split(' to ')[0])
        this.$store.commit('setAccommodationCheckOut', value.split(' to ')[1])
      }
    },
    accommodationOpenText:{
      get () {
        return this.accommodationText;

      },
      set (value) {
        this.$store.commit('setAccommodationText', value)
      }
    },
  },
  methods:
  {
    ...mapActions(['register',
                  'authenticate',
                  'validateAndupdateAppToken',
                  'createAccommodation',
                  'updateTheAccommodation',
                  'deleteTheAccommodation',
                  'uploadAccommodationIfBooked',
                  'ReturnToSingleRide',
                  'setOneMarker']),
    uploadAccommodationImage(e){
      let files = e.target.files || e.dataTransfer.files;
      let imageData = files[0];
      var today=new Date();
      let myTime = today.toUTCString().split(', ')[ 1 ].split(' ').join('_')
      const storageRef = firebase.storage().ref( 'accommodation/' + this.allRide.ride.id.toString() + '_' + myTime ).put(imageData);

      storageRef.on(`state_changed`,snapshot=>{
      this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
        }, error=>{console.log(error.message)},
      ()=>{this.uploadValue=100;
          storageRef.snapshot.ref.getDownloadURL().then((url)=>{
              const img1 = url;
              this.$store.commit('setAccommodationImage', img1)
            });
          }
        );
    },
    isUplowding(){
      return this.uploadValue > 0
    },

    handleUpdateserchpoint (place) {
      var googleContryAndCity = ''
      if( place.address_components.filter(ac=>~ac.types.indexOf('country')).length > 0 )
        googleContryAndCity = place.address_components.filter(ac=>~ac.types.indexOf('country'))[0].long_name + ", "
      if( place.address_components.filter(ac=>~ac.types.indexOf('locality')).length > 0 ){
        googleContryAndCity += place.address_components.filter(ac=>~ac.types.indexOf('locality'))[0].long_name
      }
      this.updateAccommodationGoogleContryAndCity( googleContryAndCity );
      this.updateAccommodationLat( place.geometry.location.lat());
      this.updateAccommodationLng( place.geometry.location.lng());
      // this.updateAccommodationAddress( place.formatted_address );
    },
    handleautoCompliteFormAccommodation (place) {
      this.handleUpdateserchpoint(place)
      this.updateAccommodationName( place.name )
      this.updateAccommodationAddress( place.formatted_address )
      this.updateAccommodationLink( place.website )
      this.updateAccommodationRate( place.rating )

      if ( place.geometry ){
        this.setOneMarker( {  latPoint: place.geometry.location.lat(),
                              lngPoint: place.geometry.location.lng(),
                              name:"the new point"} )
        this.$emit("serchPointChange", place);
      }
    },

    updateAccommodationName (value) {
      this.$store.commit('setAccommodationName', value)
    },
    updateAccommodationAddress (value) {
      this.$store.commit('setAccommodationAddress', value)
    },
    updateAccommodationBookingConfirmation (value) {
      this.$store.commit('setAccommodationBookingConfirmation', value)
    },
    updateAccommodationText (value) {
      this.$store.commit('setAccommodationText', value)
    },
    updateAccommodationLink (value) {
      this.$store.commit('setAccommodationLink', value)
    },
    updateAccommodationLat (lat) {
      this.$store.commit('setAccommodationLat', lat)
    },
    updateAccommodationLng (lng) {
      this.$store.commit('setAccommodationLng', lng)
    },
    updateAccommodationImage (value) {
      this.$store.commit('setAccommodationImage', value)
    },
    updateAccommodationGoogleContryAndCity (value) {
      this.$store.commit('setAccommodationGoogleContryAndCity', value)
    },
    updateAccommodationRate ( rate ) {
      this.$store.commit('setAccommodationRate', rate )
    },
    // this is not related please remov it!!!!!!!!!!!!
    register () {
      this.$store.dispatch('register', { email: this.email, password: this.password })
        .then(() => this.$router.push('/'))
    },
  },
  created() {
    // this.validateAndupdateAppToken();
    // We should upload the accommodation infirmation if this is for editing
    this.uploadAccommodationIfBooked();
   },
  mounted() {
    (function (d, sc, u) {
      var s = d.createElement(sc),
        p = d.getElementsByTagName(sc)[0];
      s.type = "text/javascript";
      s.async = true;
      s.id = "bookingElement"
      s.src = u + "?v=" + +new Date();
      p.parentNode.insertBefore(s, p);
    })(
      document,
      "script",
      "//aff.bstatic.com/static/affiliate_base/js/flexiproduct.js"
    );
  },
  destroyed() {
    (function (d, tag_name) {
      var p = d.getElementById(tag_name);
      p.remove();
    })(
      document,
      "bookingElement",
    );
    delete window.BookingAff;

  },
};
</script>

<style scoped>

/* contact page */
.trip-hero-section.contact-page {
  padding: 120px 0 20px;
}
.contact-page .trip-hero-content h2,
.contact-page .trip-hero-content h1{
  font-weight: 700;
  font-size: 25px;
  color: #FFFFFF;
  margin: 0 0 15px;
}
.contact-page .trip-hero-content h1 img{
  width: 25px;
}

.trip-contnet1-section {
  background-color: #9EDCD2;
  padding: 253px 0 20px;
}
.trip-content1{
  text-align: center;
}
.trip-content1 h3{
  font-weight: 700;
  font-size: 25px;
  color: #C83E4D;
}
.trip-content1 h4{
  font-weight: 700;
  font-size: 20px;
  color: #0D2F29;
  margin: 0;
}
.trip-content1 h4 img{
  margin-left: 10px;
}

.trip-contnet2-section {
  background-color: rgba(52, 208, 182, 0.1);
  padding: 30px 0;
}

.trip-content2 {
  text-align: center;
}
.trip-content2 p{
  font-weight: 500;
  font-size: 20px;
  color: #0D2F29;
  margin-bottom: 10px;
}
.trip-content2 h2{
  font-weight: 700;
  font-size: 30px;
  max-width: 500px;
  margin: 0 auto;
  color: #0D2F29;
  line-height: 41px;
}

.trip-contact-section a.trip-default-btn{
  display: block;
}

.trip-map-three, .trip-map-three img{
  margin-bottom: 45px;
}

.trip-contact-thumb img {
  margin-bottom: 20px;
  width: 100%;
}
.trip-contact-section {
  padding: 70px 0 150px;
}
.trip-contact-ratting{
  text-align: right;
  margin-bottom: 15px;
}
.trip-contact-ratting ul li{
  display: inline-block;
}
.trip-contact-ratting ul li a{
  font-weight: 700;
  font-size: 20px;
  color: #000000;
}

.trip-default-btn.btn-sky{
  font-weight: 500;
  font-size: 18px;
  padding: 12px;
  color: #000000;
  border: 1px solid rgba(7, 160, 195, 0.5);
  background: rgba(7, 160, 195, 0.5);
}
.trip-default-btn.btn-sky:hover{
  color: white;
}

.trip-contact-wrap {
  margin: 0px 10px;
}
.trip-input-field{
  margin-bottom: 30px;
}
.trip-input-field input,
.trip-input-field textarea{
  font-weight: 500;
  font-size: 18px;
  text-align: right;
  color: #0D2F29;
  width: 100%;
  height: 60px;
  padding: 20px 40px!important;
  border: none!important;
  background: #FFFFFF;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 22px;
}
.trip-input-field textarea{
  height: 312px;
}
.trip-contact-wrap .trip-date-wrap{
  max-width: 100%
}

.input.border-left{
  border-left: 1px solid #BABABA!important;
}
.trip-contact-wrap .trip-date-wrap {
  max-width: 100%;
  justify-content: space-between;
  padding: 8px 25px;
  margin-bottom: 30px;
}

.trip-default-btn.btn-sky.outline-red {
  color: #C83E4D;
  border: 2px solid #C83E4D;
  background: transparent;
}
a.trip-default-btn.btn-sky.btn-green {
  background: #0B6758;
  color: #fff;
}
a.trip-default-btn.btn-sky.btn-red {
  background: red;
  color: black;
}
.trip-contact-wrap .trip-btn-wraper {
  margin: 60px 0;
}
.trip-social {
  text-align: center;
}










</style>