<template>
  <div>
    <br>
    <gmap-map
      ref="mapRef"
      :center="center"
      :zoom="12"
      :class="[getClass()]"
    >
    </gmap-map>
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'GlobalMap',
  props: ['passed_class',
          ],

  computed: mapGetters(['markers', 'center']),
  methods: 
  {
    ...mapActions([ 'geolocate',
                    'setAppMap',
                    'fetchSerchAttractionsByRide',
                    'setOneMarker']),
   getClass(){
    return this.passed_class
   },
  },
  mounted(){
    this.$refs.mapRef.$mapPromise.then((map) =>{
      this.$store.commit('setAppMap', map)

    });    
  }
};
</script>


<style scoped >

.single_ride_map{
  position: relative;
}
</style>
