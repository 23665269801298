import api from '../../api/appauth';
// import qs from 'qs';
import { router } from '../../main';
import firebase from 'firebase'

const state = {
  apptoken: window.localStorage.getItem('travel_app_token'),
  email: '',
  userName: window.localStorage.getItem('travel_app_user_name'),
  password: '',
  rePassword: '',
  isNewUser: false,
};

const getters = {
  email: state => state.email,
  userName: state => state.userName,
  password: state => state.password,
  rePassword: state => state.rePassword,
  apptoken: state => state.apptoken,
  isAppLoggedIn: state => !!state.apptoken,
  userId: state => {
    if( state.apptoken ){
      return JSON.parse(atob(state.apptoken.split('.')[1])).sub.id;
    }
    return null;
  },
  isNewUser: state => state.isNewUser,
  compareToUserId: ( state ) => ( authorId ) => {
    
    if( ! state.apptoken ){
      return false;
    }
    const userId = JSON.parse(atob(state.apptoken.split('.')[1])).sub.id;
    if( userId == authorId ){
      return true;
    }
    return false
  },

};

const actions = {
  resetParameters({commit}) {
    commit('setToken1', null);
    commit('setEmail', '');
    commit('setPassword', '');
    commit('setRePassword', '');
    commit('setIsNewUser', false);
  },

  async loginToAppWithFaceBook({dispatch}) {
    // at the login with oath we have an issue that the user can log in with one operator( face or google )
    // there is an option to fix it by adding the user other acount to the oath or  removing the dependency of firebase 
    // we can see this information about adding other operator to firebase auutenticated user:
    // https://stackoverflow.com/questions/44015751/firebase-js-api-auth-account-exists-with-different-credential
    var provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope('email');
    try {
      var result = await firebase.auth().signInWithPopup(provider);
    } catch (err) {
      if(err.code == "auth/account-exists-with-different-credential"){
        dispatch( 'showModalWithParameters',
        { errorNumber : "account_exists_with_different_credential",
          type:'textConverter' 
        } );
      }
      return;
    }
    var email = result.user.email;
    if ( email == null ) {
      dispatch( 'showModalWithParameters',
      { errorNumber : "email_is_not_baind_to_this_acount",
        type:'textConverter' 
      } );
      return;
    }
    dispatch('authenticate', {  email: result.user.email,
      facebookAccessToken: result.credential.accessToken })
    dispatch('closeLoginForm');
  },

  async loginToAppWithGoogle({dispatch}) {
    var provider = new firebase.auth.GoogleAuthProvider();
    try {
      var resolt = await firebase.auth().signInWithPopup(provider)
    } catch (err) {
      if(err.code == "auth/account-exists-with-different-credential"){
        dispatch( 'showModalWithParameters',
        { errorNumber : "account_exists_with_different_credential",
          type:'textConverter' 
        } );
      }
      return;
    }
    dispatch('authenticate', {  email: resolt.user.email,
                                googleIdToken: resolt.credential.idToken })
    dispatch('closeLoginForm');
    // TODO We should present error if ther is aproblem!!!!
  },
  async registerNewUser({ rootState, commit, dispatch } ){
    const { email } = rootState.appauth;
    const { password } = rootState.appauth;
    const { userName } = rootState.appauth;
    const response = await api.registerNewUser( email, password, userName );
    if( response.data.ServerMassageCode && ( response.data.ServerMassageCode != 1 ) ){
      dispatch( 'showModalWithParameters',
                { errorNumber : response.data.ServerMassageCode,
                  type:'textConverter' 
                } );
    }
    commit('setToken1', response.data.token);
  },
  openLoginForm({ dispatch }) {
    dispatch( 'showModal', "mainLogIn" );    
  },
  closeLoginForm({ dispatch }) {
    dispatch( 'showModal', "" );    
  },
  async appLogOut({ commit, dispatch } ) {
    commit('setToken1', null);
    window.localStorage.removeItem('travel_app_token');
    dispatch('resetParameters');
    firebase.auth().signOut()
  },
  equalToUserId( { getters, state }, authorId ){
    if( ! state.apptoken ){
      return false;
    }
    const userId = getters.userId;
    if( userId == authorId ){
      return true;
    }
    return false
  },
  async authenticate({ rootState, commit, dispatch }, userAuthInformation={} ) {
    var response
    var userEmail
    if ( 'googleIdToken' in userAuthInformation ){
      userEmail = userAuthInformation.email
      response = await api.authenticate( userAuthInformation.email, 0, userAuthInformation.googleIdToken, "google" );
    }
    else if ( 'facebookAccessToken' in userAuthInformation ){
      userEmail = userAuthInformation.email
      response = await api.authenticate( userAuthInformation.email, 0, userAuthInformation.facebookAccessToken, "face-book" );
    }
    else {
      const { email } = rootState.appauth;
      const { password } = rootState.appauth;
      userEmail = rootState.appauth.email
      response = await api.authenticate( email, password);
      if( response.data.ServerMassageCode && response.data.ServerMassageCode != 1 && response.data.ServerMassageCode != 'secsses' ){
        dispatch( 'showModalWithParameters',
                  { errorNumber : response.data.ServerMassageCode,
                    type:'textConverter' 
                  } );
      }
    }
    commit('setToken1', response.data.token);
    commit('setUserName', response.data.userName);
    dispatch( 'showModalWithParameters',
      { errorNumber : "userLoggedInSuccessfully",
        textToAdd : userEmail,
        type:'textConverter' 
      } );
    // TODO We should popup this masssege if  aetToke is False
    // and we should close the popup window at this page
    // console.log( response.data.message )
    // commit('setToken1', response.data.token);
  },
  validateAndupdateAppToken: ({ state, dispatch }) =>{
    const jwt = state.apptoken;
    if (!jwt || jwt.split('.').length < 3) { 
      dispatch('appLogOut');
      return;
    }
    const data = JSON.parse(atob(jwt.split('.')[1]))
    const exp = new Date(data.exp * 1000) // JS deals with dates in milliseconds since epoch
    const now = new Date()
    if (now > exp){
      dispatch('appLogOut');  
    }
  },
  goToTermsOfUse({dispatch}){
    dispatch( 'showModal', '' );    
    router.push('/terms_of_use')
  },

};

const mutations = {
  setToken1: (state, token) => {
    state.apptoken = token;
    if( token ){
      window.localStorage.setItem('travel_app_token', token);
    }
    else{
      window.localStorage.removeItem('travel_app_token');
    }
  },
  setEmail: (state, email) => {
    state.email = email;
  },
  setUserName: (state, userName) => {
    state.userName = userName;
    if( userName ){
      window.localStorage.setItem('travel_app_user_name', userName);
    }
    else{
      window.localStorage.removeItem('travel_app_user_name');
    }
  },
  setPassword: (state, password) => {
    state.password = password;
  },
  setRePassword: (state, rePassword) => {
    state.rePassword = rePassword;
  },
  setIsNewUser: (state, isNewUser) => {
    state.isNewUser = isNewUser;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
