<template>
  <div>
    <input type="file" multiple @change="onFileChange">
    <button @click="uploadFiles">Upload</button>
    <div v-for="(meta, index) in metadataList" :key="index">
      <h3>Metadata for {{ meta.filename }}</h3>
      <p><strong>Size:</strong> {{ meta.size }} bytes</p>
      <p><strong>Type:</strong> {{ meta.type }}</p>
      <p><strong>Date Taken:</strong> {{ meta.dateTaken }}</p>
      <p><strong>Location:</strong> Latitude: {{ meta.location.latitude }}, Longitude: {{ meta.location.longitude }}</p>
      <p><strong>Uploaded At:</strong> {{ meta.uploadedAt }}</p>
      <p><strong>Download URL:</strong> {{ meta.url }}</p>
    </div>
  </div>
</template>

<script>
import EXIF from 'exif-js';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

export default {
  data() {
    return {
      files: [],
      metadataList: [],
    };
  },
  methods: {
    onFileChange(event) {
      this.files = event.target.files;
    },
    extractExifData(file) {
      return new Promise((resolve, reject) => {
        EXIF.getData(file, function() {
          const exifData = EXIF.getAllTags(this);
          if (Object.keys(exifData).length === 0) {
            reject('No EXIF data found');
          } else {
            resolve({
              dateTaken: exifData.DateTimeOriginal,
              gpsLatitude: exifData.GPSLatitude,
              gpsLongitude: exifData.GPSLongitude,
            });
          }
        });
      });
    },
    async uploadFiles() {
      const promises = [];
      for (let i = 0; i < this.files.length; i++) {
        const file = this.files[i];
        const storageRef = firebase.storage().ref(`attractionsTest/${file.name}`);
        const uploadTask = storageRef.put(file);

        promises.push(
          uploadTask.then(async () => {
            const exifData = await this.extractExifData(file).catch((error) => {
              console.error('Error extracting EXIF data:', error);
              return {};
            });
            const downloadURL = await storageRef.getDownloadURL();
            const metadata = {
              filename: file.name,
              size: file.size,
              type: file.type,
              dateTaken: exifData.dateTaken || null,
              location: exifData.gpsLatitude && exifData.gpsLongitude ? {
                latitude: exifData.gpsLatitude,
                longitude: exifData.gpsLongitude,
              } : { latitude: null, longitude: null },
              uploadedAt: new Date().toLocaleString(),
              url: downloadURL,
            };

            this.metadataList.push(metadata);
            return firebase.firestore().collection('images').add(metadata);
          }).catch((error) => {
            console.error('Error uploading file:', error);
          })
        );
      }

      Promise.all(promises)
        .then(() => {
          console.log('All files uploaded successfully');
        })
        .catch((error) => {
          console.error('There was an error uploading the files!', error);
        });
    },
  },
};
</script>
