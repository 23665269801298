
import api from '../../api/editRide';
import attractionApi from '../../api/attraction';
import { router } from '../../main';

const state = {
  newAttractionTitle: '',
  newAttractionAddress: '',
  newAttractionText: '',
  newAttractionLink: '',
  newAttractionOpeningHours: null,
  newAttractionReview: null,
  newAttractionRate: null,
  newAttractionLat: null,
  newAttractionLng: null,
  newAttractionImage: null,
  newAttractionPreviewImage: null,
  newAttractionPlannedDate: null,
  newAttractionId: null,
  newAttractionMode: null,
  newAttractionPlaceId: null,
  newAttractionGoogleImages: [],
  newAttractionGoogleImage: null,
  newAttractionErrores: []
};

const getters = {
  newAttractionTitle: state => state.newAttractionTitle,
  newAttractionAddress: state => state.newAttractionAddress,
  newAttractionText: state => state.newAttractionText,
  newAttractionLink: state => state.newAttractionLink,
  newAttractionOpeningHours: state => state.newAttractionOpeningHours,
  newAttractionReview: state => state.newAttractionReview,
  isNewAttractionReview: state => state.newAttractionReview!=null,
  newAttractionRate: state => state.newAttractionRate,
  isNewAttractionRate: state => state.newAttractionRate!=null,
  newAttractionLat: state => state.newAttractionLat,
  newAttractionLng: state => state.newAttractionLng,
  newAttractionImage: state => state.newAttractionImage,
  isNewAttractionImage: state => state.newAttractionImage!=null,
  newAttractionPreviewImage: state => state.newAttractionPreviewImage,
  newAttractionPlannedDate: state => state.newAttractionPlannedDate,
  newAttractionId: state => state.newAttractionId,
  newAttractionMode: state => state.newAttractionMode,
  isNewAttractionEditMode: state => state.newAttractionMode=='edit',
  newAttractionPlaceId: state => state.newAttractionPlaceId,
  newAttractionGoogleImages: state => state.newAttractionGoogleImages,
  newAttractionGoogleImage: state => state.newAttractionGoogleImage,
  newAttractionErrores: state => state.newAttractionErrores,
  };

const actions = {
  newAttractionLoadDefault({commit}){
    commit( 'setNewAttractionPlannedDate', null );
    commit( 'setNewAttractionId', null );
    commit( 'setNewAttractionTitle', '' );
    commit( 'setNewAttractionAddress', '' );
    commit( 'setNewAttractionText', '' );
    commit( 'setNewAttractionLink', '' );
    commit( 'setNewAttractionOpeningHours', null );
    commit( 'setNewAttractionReview', null );
    commit( 'setNewAttractionRate', null ); 
    commit( 'setNewAttractionLat', null );
    commit( 'setNewAttractionLng', null );
    commit( 'setNewAttractionImage', null );
    commit( 'setNewAttractionPreviewImage', null );
    commit( 'setNewAttractionMode', null );
    commit( 'setNewAttractionPlaceId', null );
    commit( 'setNewAttractionGoogleImages', [] );
    commit( 'setNewAttractionGoogleImage', null );
    commit( 'setNewAttractionErrores', [] );
  },

  async uploadAttractionIfEditMode({ dispatch,  getters } ){
    const mode = getters.newAttractionMode;
    if( mode != 'edit'){ return; }
    dispatch( 'uploadAttractionFromServer' );
  },

  async uploadAttractionFromServer( { getters, commit },
                                    args = {  'attractionId': 0,
                                              'sorce':'internal' } ){
    if( args.attractionId == 0 ){
      args.attractionId = getters.newAttractionId;
      args.sorce = 'internal'
    }
    const response = await attractionApi.getAttraction( args.attractionId, args.sorce);
    const data = response.data;
    commit('setNewAttractionTitle', data.title );
    commit('setNewAttractionAddress', data.address );
    commit('setNewAttractionText', data.text );
    commit('setNewAttractionLink', data.link );
    commit('setNewAttractionRate', data.rate );
    commit('setNewAttractionLat', data.lat );
    commit('setNewAttractionLng', data.lng );
    commit('setNewAttractionImage', data.image );
    commit('setNewAttractionPlaceId', data.placeId );
    commit('setNewAttractionImage', data.imageUrl );
    if( data.openingHours ){
      commit('setNewAttractionOpeningHours', data.openingHours.weekday_text );
    }
    if( data.review ){
      commit('setNewAttractionReview', data.review );
    }
  },

  async validateAttractionInput( { state, commit }, attraction ){
    commit( 'setNewAttractionErrores', [] );
    if ( attraction['title'] == '' )
      state.newAttractionErrores.push( "כותרת")
    
    if ( attraction['lat'] == null )
      state.newAttractionErrores.push( "להוספת פרטים נוספים -> שינוי המיקום על המפה")

    if ( attraction['link'] && attraction['link'].length > 140 )
      state.newAttractionErrores.push( "הליק של האתר ארוך מידי")

    if ( attraction['title'] && attraction['title'].length > 140 )
      state.newAttractionErrores.push( "הכותרת ארוכה מידי")

    if ( attraction['address'] && attraction['address'].length > 140 )
      state.newAttractionErrores.push( "הכתובת ארוכה מידי")

    if ( attraction['imageUrl'] && attraction['imageUrl'].length > 200 ){
      state.newAttractionErrores.push( "יש בעיה בטעינת התמונה")
      // print( "the image link lengh is to long!!!")
    }
    if ( state.newAttractionErrores.length > 0 )
      return false
    return true;
  },

  async updateAttraction({ state, rootState, getters, dispatch } ){
    const rideId = rootState.ride.rideId;
    var attraction = {}
    attraction['title'] = state.newAttractionTitle;
    attraction['address'] = state.newAttractionAddress;
    attraction['text'] = state.newAttractionText;
    attraction['link'] = state.newAttractionLink;
    attraction['rate'] = state.newAttractionRate;
    attraction['lat'] = state.newAttractionLat;
    attraction['lng'] = state.newAttractionLng;
    attraction['imageUrl'] = state.newAttractionImage;
    attraction['placeId'] = state.newAttractionPlaceId;
    if ( ! await dispatch('validateAttractionInput', attraction ) )
      return;
    const { apptoken } = rootState.appauth;
    const attractionId = getters.newAttractionId;
    attractionApi.updateAttraction(  apptoken,
                                     attractionId,
                                     attraction);
    await dispatch('newAttractionLoadDefault');
    router.push('/single_ride_with_map/' + rideId);
  },
  async createAttraction({ state, rootState, getters, dispatch } ){
    var attraction = {}
    const rideId = rootState.ride.rideId;
    const plannedDate = getters.newAttractionPlannedDate;
    attraction['plannedDate'] = plannedDate;
    attraction['title'] = state.newAttractionTitle;
    attraction['address'] = state.newAttractionAddress;
    attraction['text'] = state.newAttractionText;
    attraction['link'] = state.newAttractionLink;
    attraction['rate'] = state.newAttractionRate;
    attraction['lat'] = state.newAttractionLat;
    attraction['lng'] = state.newAttractionLng;
    attraction['googleImage'] = state.newAttractionGoogleImage;
    attraction['imageUrl'] = state.newAttractionImage;
    attraction['placeId'] = state.newAttractionPlaceId;
    if ( ! await dispatch('validateAttractionInput', attraction ) )
      return;
    const { apptoken } = rootState.appauth;
    dispatch( 'showModalWithParameters',
    { errorNumber : "updating_the_ride",
      type:'textConverter' } );
    var response = await api.createAttraction(  apptoken,
                                                rideId,
                                                attraction);
    dispatch( 'showModal', '' );
    if( response.data.ServerMassageCode != "success" ){
      dispatch( 'showModalWithParameters',
      { errorNumber : response.data.ServerMassageCode,
        type:'textConverter' 
      } );
      return
    }
    await dispatch( 'newAttractionLoadDefault' );
    router.push('/single_ride_with_map/' + rideId);
  },
  goToNewAttraction({ rootState, commit, dispatch }, dayIndex ) {
    dispatch( 'newAttractionLoadDefault' );
    const ride = rootState.ride;
    const { apptoken } = rootState.appauth;
    if( apptoken ){
      var palannedDate = new Date(ride.ride.attractionsByDay[ dayIndex ].dateTime);
      palannedDate.setHours(23,59);
      commit('setNewAttractionPlannedDate', palannedDate.toLocaleString("en-US"));
      router.push('/add_new_attraction_to_ride');
    }
    else{
      dispatch( 'openLoginForm' );
    }
  },
  editAttraction({ commit, dispatch }, attractionId ){
    dispatch('newAttractionLoadDefault' );
    commit( 'setNewAttractionId', attractionId )
    commit( 'setNewAttractionMode', 'edit' )
    dispatch('uploadAttractionFromServer' );
    router.push('/add_new_attraction_to_ride');
  },

  async lowedAttractionAndShow({ dispatch }, args = {  'attractionId': 0,
                                                               'sorce':'internal' } )
  {
    dispatch( "showModal", "loadingIcon" );
    await dispatch( "lowedAttraction", args );
    dispatch( "showModal", "attraction" );
                                                    
  },
  async lowedAttraction({ commit, dispatch }, args = {  'attractionId': 0,
                                                        'sorce':'internal' } ){
    dispatch('newAttractionLoadDefault' );
    commit( 'setNewAttractionId', args.attractionId )
    // commit( 'setNewAttractionMode', 'edit' )
    await dispatch('uploadAttractionFromServer', args );
  },
  viewAttraction({ dispatch }, args = {  'attractionId': 0,
                                                        'sorce':'internal' } ){
    dispatch('lowedAttraction', args );
    router.push('/attractionInformation');
  },

  async uplowadGoogleImages({ state }, place ){
    var { newAttractionGoogleImages } = state;
    newAttractionGoogleImages.length = 0
    for( var i = 0; i < 6; i++ ){
      newAttractionGoogleImages.push( place.photos[i].getUrl({maxWidth: 500, maxHeight: 500}) )
    }
  },
};

const mutations = {
  setNewAttractionTitle: (state, newAttractionTitle) => {
    state.newAttractionTitle = newAttractionTitle;
  },
  setNewAttractionAddress: (state, newAttractionAddress) => {
    state.newAttractionAddress = newAttractionAddress;
  },
  setNewAttractionText: (state, newAttractionText) => {
    state.newAttractionText = newAttractionText;
  },
  setNewAttractionLink: (state, newAttractionLink) => {
    state.newAttractionLink = newAttractionLink;
  },
  setNewAttractionOpeningHours: (state, openingHours) => {
    state.newAttractionOpeningHours = openingHours;
  },
  setNewAttractionReview: (state, review) => {
    state.newAttractionReview = review;
  },
  setNewAttractionRate: (state, newAttractionRate) => {
    state.newAttractionRate = newAttractionRate;
  },
  setNewAttractionLat: (state, newAttractionLat) => {
    state.newAttractionLat = newAttractionLat;
  },
  setNewAttractionLng: (state, newAttractionLng) => {
    state.newAttractionLng = newAttractionLng;
  },
  setNewAttractionImage: (state, newAttractionImage) => {
    state.newAttractionImage = newAttractionImage;
  },
  setNewAttractionPreviewImage: (state, newAttractionPreviewImage) => {
    state.newAttractionPreviewImage = newAttractionPreviewImage;
  },
  setNewAttractionPlannedDate: (state, plannedDate) => {
    state.newAttractionPlannedDate = plannedDate;
  },
  setNewAttractionId: (state, attractionId) => {
    state.newAttractionId = attractionId;
  },
  setNewAttractionMode: (state, mode) => {
    state.newAttractionMode = mode;
  },
  setNewAttractionPlaceId: (state, PlaceId) => {
    state.newAttractionPlaceId = PlaceId;
  },
  setNewAttractionGoogleImages: (state, googleImages) => {
    state.newAttractionGoogleImages = googleImages;
  },
  setNewAttractionGoogleImage: (state, googleImage) => {
    state.newAttractionGoogleImage = googleImage;
  },
  setNewAttractionErrores: ( state, errores) => {
    state.newAttractionErrores = errores;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
